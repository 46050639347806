
<div class="row">
  <span class="closeIconNew" (click)="actionCancel()" *ngIf="detailProvider"> X </span>
</div>

<div class="contaner" style="    height: 95%;">
  <div *ngIf="detailProvider" style="height:100%; margin-top: 20px; display: flex; flex-direction: column; gap: 10px;">
    <div class="width-100">
      <div class="width-30">
        <div class="order-details">
          <div class="box-header">
            Order Details
          </div>
          <div class="box-content">
            <div class="item-list">
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">folder</mat-icon>
                </div>
                <div class="item-label">{{ detailProvider.taxInvoiceNo }}</div>
              </div>
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">shop</mat-icon>
                </div>
                <div class="item-label">{{ detailProvider.shopName }}</div>
              </div>
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">date_range</mat-icon>
                </div>
                <div class="item-label">
                  {{ detailProvider.orderTime | date: "medium" }}
                </div>
              </div>
              <div class="item">
                <!-- <div class="item-icon"> <mat-icon class="order-dtl-icon">payment</mat-icon> </div>
                  <div class="item-label"> Cash on Delivery </div> -->
              </div>
              <div class="item">
                <!-- <div class="item-icon"> <mat-icon class="order-dtl-icon">local_shipping</mat-icon> </div>
                  <div class="item-label"> Shipping User </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="width-30">
        <div class="order-details">
          <div class="box-header">
            Customer Details
          </div>
          <div class="box-content">
            <div class="item-list">
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">account_circle</mat-icon>
                </div>
                <div class="item-label">
                  {{ detailProvider.orderCustomerDto.title }}
                  {{ detailProvider.orderCustomerDto.firstName }}
                  {{ detailProvider.orderCustomerDto.lastName }}
                </div>
              </div>
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">location_city</mat-icon>
                </div>
                <div class="item-label">
                  {{ detailProvider.orderCustomerDto.address }}
                </div>
              </div>
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">email</mat-icon>
                </div>
                <div class="item-label">
                  {{ detailProvider.orderCustomerDto.email }}
                </div>
              </div>
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">phone</mat-icon>
                </div>
                <div class="item-label">
                  {{ detailProvider.orderCustomerDto.phoneNumber }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="width-40">
        <div class="timeline">
          <ul>
            <li class="list-item" *ngFor="
              let item of detailProvider.orderHistorySampleDto | reversePipe
            ">
              <h3 class="order-label">
                <span style="border: 1px;" class="order" [style.background-color]="item.backgroundColor"
                  [style.color]="item.foreColor" [style.border-color]="item.borderColor">
                  {{ item.displayText }}
                </span>
                
                <p class="date">{{ item.processedAt }}</p>
              </h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="order-remmarks" *ngIf="detailProvider.remarks !==undefined && detailProvider.remarks !==null && detailProvider.remarks.length>0">
     <div class="remarks" [innerHtml]="getOrderRemarks(detailProvider)"></div>
    </div>
    <div class="order-table">
      <div class="padd-10">
        <div class="invoice-model">
          <table class="table table-bordered invoice-table-custom">
            <thead class="invoice">
              <tr class="head">
                <td class="text-left">Item</td>
                <td class="text-left">Item Category</td>
                <td style="text-align: right">Quantity</td>
                <td style="text-align: right">Unit Price</td>
                <td style="text-align: right">Discount</td>
                <td style="text-align: right">Total</td>
              </tr>
            </thead>
            <tbody class="tbody">
              <ng-container *ngFor="let item of detailProvider.orderDetailDto">
                <tr>
                  <td class="text-left">{{ item.name }}</td>
                  <td class="text-left">{{ item.subClassName }}</td>
                  <td style="text-align: right">{{ item.qty }}</td>
                  <td style="text-align: right">
                    {{ item.fixedPrice | currency }}
                  </td>
                  <td style="text-align: right">
                    <span *ngIf="item.discountAmount !== 0">{{ item.discountAmount | currency }}</span>
                  </td>
                  <td style="text-align: right">{{ item.itemTotal | currency }}</td>
                </tr>
                <!-- <ng-container *ngIf="item.customization !== null && item.customization !== undefined "> -->
                  @if(item.itemType == 2){
                <tr *ngFor="let cust of getCustomizations(item)">
                  <!-- <td colspan=2 class="text-left" style="padding-left: 35px;"><i style="margin-right: 10px;"
                      class="fa fa-caret-right" aria-hidden="true"></i>{{ cust.name }}</td> -->
                      <td colspan=2 class="text-left" style="padding-left: 35px;">
                        <div style="display: flex; align-items: center;">
                         <mat-icon>filter_tilt_shift</mat-icon>
                        <span style="margin-left: 10px;"> {{ cust.name }} </span>
                        </div>
                        
                      </td>
                  <!-- <td class="text-left">{{ item.subClassName }}</td> -->
                  <td style="text-align: right">{{ cust.qty }}</td>
                  <td style="text-align: right">
                    {{ cust.price | currency }}
                  </td>
                  <td style="text-align: right">
                    <span *ngIf="cust.discountAmount !== 0">{{ cust.discountAmount | currency }}</span>
                  </td>
                  <td style="text-align: right">{{ cust.total | currency }}</td>
                </tr>
              }
              @if(item.itemType == 3){
                @for(option of getComboOptions(item); track option.name){
                <tr>
                  <td colspan="2" class="text-left" style="padding-left: 35px;">
                    <div style="display: flex; align-items: center;">
                      <mat-icon>brightness_1</mat-icon>
                      <span style="margin-left: 10px;">{{ option.name }}</span>
                    </div>
                  </td>
                  <td style="text-align: right">{{ option.qty }}</td>
                  <td style="text-align: right">{{ option.price | currency }}</td>
                  <td style="text-align: right"><span *ngIf="option.discountAmount !== 0">{{ option.discountAmount | currency }}</span></td>
                  <td style="text-align: right">{{ option.total | currency }}</td>
                </tr>
                
                  <tr *ngFor="let cust of getCustomizations(option)">
                    <!-- <td colspan=2 class="text-left" style="padding-left: 35px;"><i style="margin-right: 10px;"
                        class="fa fa-caret-right" aria-hidden="true"></i>{{ cust.name }}</td> -->
                        <td colspan=2 class="text-left" style="padding-left: 60px;">
                          <div style="display: flex; align-items: center;">
                           <mat-icon>filter_tilt_shift</mat-icon>
                          <span style="margin-left: 10px;"> {{ cust.name }} </span>
                          </div>
                          
                        </td>
                    <!-- <td class="text-left">{{ item.subClassName }}</td> -->
                    <td style="text-align: right">{{ cust.qty }}</td>
                    <td style="text-align: right">
                      {{ cust.price | currency }}
                    </td>
                    <td style="text-align: right">
                      <span *ngIf="cust.discountAmount !== 0">{{ cust.discountAmount | currency }}</span>
                    </td>
                    <td style="text-align: right">{{ cust.total | currency }}</td>
                  </tr>
                }
                
              }

                <!-- </ng-container> -->
                <tr *ngIf="item.remarks !==undefined && item.remarks.length>0">
                  <td colspan="6">
                    **
                    {{item.remarks}}
                  </td>
                </tr>
              </ng-container>
              <tr>
                <td colspan="5" style="text-align: right">Total Amount</td>
                <td style="text-align: right">
                  {{ detailProvider.detailTotal | currency }}
                </td>
              </tr>
              <tr>
                <td colspan="5" style="text-align: right">Discount</td>
                <td style="text-align: right">
                  {{
                  detailProvider.totalDetailDiscount
                  | currency
                  }}
                </td>
              </tr>
              <tr>
                <td colspan="5" style="text-align: right">Net Amount</td>
                <td style="text-align: right">
                  {{ detailProvider.totalAmountPaid | currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>