import { OrdersService } from '../orders.service';
import { Component, Inject ,Pipe, PipeTransform,} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ReversePipe } from 'src/app/common/pipes/reverse.pipe';
@Component({
  selector: "app-order-view",
  templateUrl: "./order-view.component.html",
  styleUrl: "./order-view.component.scss",
})
export class OrderViewComponent {
  displayedColumns: string[] = ["paymentAddress", "shippingAddress"];
  addressSource: any;
  detailProvider: any;
  constructor(
    private builder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<OrderViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: OrdersService, // Inject your RewardService
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): any {
    this.orderService.getOrderDetails(this.data.id).subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.detailProvider = response.data;

          for (const item of this.detailProvider.orderDetailDto) {
            if (item.itemType === 3) {
              const comboOptions =
                item.comboOptions !== null &&
                item.comboOptions !== undefined &&
                item.comboOptions.length > 0
                  ? JSON.parse(item.comboOptions)
                  : [];
              let totalAmt = 0;
              let totalDiscount = 0;
              for (const combo of comboOptions) {
                totalAmt += combo.price;
                totalDiscount += combo.discountAmount;
                const customizations =
                  combo.customizations !== null &&
                  combo.customizations !== undefined &&
                  combo.customizations.length > 0
                    ? JSON.parse(combo.customizations)
                    : [];
                for (const cust of customizations) {
                  totalAmt += cust.price;
                  totalDiscount += cust.discountAmount;
                }
              }
              item.fixedPrice = totalAmt
              item.discountAmount = totalDiscount;
              item.itemTotal =item.fixedPrice*item.qty-item.discountAmount;
            }
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  getOrderRemarks(order: any) {
    var remarks = "";
    if (
      order.remarks !== null &&
      order.remarks !== undefined &&
      order.remarks.length > 0
    ) {
      var rex = /\r\n|\n|\r/gm;
      remarks = "** " + order.remarks.replace(rex, "<br>");
    }
    return remarks;
  }

  getCustomizations(item: any): any {
    return item.customizations !== null &&
      item.customizations !== undefined &&
      item.customizations.length > 0
      ? JSON.parse(item.customizations)
      : [];
  }

  getComboOptions(item: any): any {
    return item.comboOptions !== null &&
      item.comboOptions !== undefined &&
      item.comboOptions.length > 0
      ? JSON.parse(item.comboOptions)
      : [];
  }

  actionCancel(): void {
    this.dialogRef.close();
  }
}
