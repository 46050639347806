import { Injectable } from "@angular/core";
import { RequestData } from "src/app/common/models/request-data.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }
  /**
   * 
   * get company settings
   */
  loadCompanySetting() {
    return this.http.get(environment.baseUrl + "company/get");
  }
  /**
  * @param serviceId
  * get order service timing info
  */
  getOrderServiceTimingByService(serviceId: number): any {
    return this.http.get<any>(
      environment.baseUrl +
      "company/get-service-time-slots?serviceId=" +
      serviceId
    );
  }
  /**
  * @param bodyParams
  * Calls the saveOrderServiceTimingData API to update the order service timing
  */
  saveOrderServiceTimingData(bodyParams: any): any {
    return this.http.post<any>(
      environment.baseUrl + "company/set-service-time-slots",
      bodyParams
    );
  }
  /**
   * @param requestParams
   * Calls the update API to update the company settings
   */
  updateCompanySettings(requestParams: any) {
    return this.http.post(
      environment.baseUrl + "company/save",
      requestParams
    );
  }

  setNotice(formData: any): any {
    return this.http.post<any>(
      environment.baseUrl + "company/set-notice",
      formData
    );
  }
  resetNotice(): any {
    return this.http.post(environment.baseUrl + "company/reset-notice",'');
  }

}
